<template>
  <div class="child-safety-container">
    <h1>Child Safety Standards</h1>
    <p>Last Updated: {{ lastUpdated }}</p>

    <section>
      <h2>1. Introduction</h2>
      <p>At Kinfolk, we are committed to ensuring the safety and well-being of all users, especially children. This document outlines our standards and practices to prevent child sexual abuse and exploitation (CSAE) and to maintain a safe environment for all users.</p>
    </section>

    <section>
      <h2>2. Our Commitment to Child Safety</h2>
      <p>Kinfolk is designed as a family-oriented platform that prioritizes user safety. We have implemented various measures to protect children from potential harm, including:</p>
      <ul>
        <li>Age verification processes</li>
        <li>Content moderation systems</li>
        <li>Reporting mechanisms for inappropriate content or behavior</li>
        <li>Regular review and updates to our safety protocols</li>
      </ul>
    </section>

    <section>
      <h2>3. Prevention of Child Sexual Abuse and Exploitation</h2>
      <p>We have zero tolerance for child sexual abuse and exploitation. Our platform implements the following safeguards:</p>
      <ul>
        <li>Automated systems to detect and prevent the sharing of child sexual abuse material (CSAM)</li>
        <li>Immediate removal of any content that violates our standards</li>
        <li>Cooperation with law enforcement agencies when necessary</li>
        <li>Regular training for our content moderation team on identifying potential risks</li>
      </ul>
    </section>

    <section>
      <h2>4. User Reporting and Response</h2>
      <p>We encourage all users to report any content or behavior that may violate our child safety standards. Our response protocol includes:</p>
      <ul>
        <li>Immediate review of all reports related to child safety</li>
        <li>Temporary suspension of accounts under investigation</li>
        <li>Permanent removal of accounts found to be in violation of our standards</li>
        <li>Reporting to relevant authorities when appropriate</li>
      </ul>
      <p>To report a concern, please contact our safety team at <a href="mailto:safety@kinfolk.world">safety@kinfolk.world</a>.</p>
    </section>

    <section>
      <h2>5. Parental Controls and Education</h2>
      <p>We provide tools and resources to help parents and guardians:</p>
      <ul>
        <li>Monitor their children's activities on our platform</li>
        <li>Restrict certain features based on age appropriateness</li>
        <li>Access educational materials about online safety</li>
        <li>Engage in conversations about digital citizenship and online safety</li>
      </ul>
    </section>

    <section>
      <h2>6. Continuous Improvement</h2>
      <p>We are committed to continuously improving our child safety measures through:</p>
      <ul>
        <li>Regular audits of our safety systems</li>
        <li>Staying informed about emerging threats and best practices</li>
        <li>Collaborating with safety experts and organizations</li>
        <li>Incorporating user feedback into our safety protocols</li>
      </ul>
    </section>

    <section>
      <h2>7. Contact Information</h2>
      <p>If you have any questions or concerns about our child safety standards, please contact us at:</p>
      <p>Email: <a href="mailto:safety@kinfolk.world">safety@kinfolk.world</a></p>
      <p>For immediate assistance with a child safety concern, please contact your local law enforcement agency.</p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ChildSafetyStandards',
  data() {
    return {
      lastUpdated: new Date().toLocaleDateString()
    };
  }
};
</script>

<style scoped>
.child-safety-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}

h2 {
  font-size: 1.75rem;
  color: #4F46E5;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

p {
  line-height: 1.6;
  color: #4B5563;
  margin-bottom: 1rem;
}

ul {
  margin-bottom: 1.5rem;
  padding-left: 1.5rem;
}

li {
  margin-bottom: 0.5rem;
  line-height: 1.6;
  color: #4B5563;
}

section {
  margin-bottom: 2rem;
}

a {
  color: #4F46E5;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
