import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import PrivacyPolicy from '@/components/PrivacyPolicy.vue';
import ChildSafetyStandards from '@/components/ChildSafetyStandards.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/how-its-work',
    name: 'HowItsWork',
    component: () => import(/* webpackChunkName: "about" */ '../views/HowItsWorkView.vue')
  },
  {
    path: '/waitlist',
    name: 'Waitlist',
    component: () => import(/* webpackChunkName: "about" */ '../views/Waitlist'),
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/ResetPassword'),
  },
  {
    path: '/support',
    name: 'Support',
    component: () => import(/* webpackChunkName: "support" */ '../views/SupportView.vue')
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/account-deletion',
    name: 'AccountDeletion',
    component: PrivacyPolicy,
    props: { showDeletionForm: true }
  },
  {
    path: '/child-safety-standards',
    name: 'ChildSafetyStandards',
    component: ChildSafetyStandards
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return { el: to.hash, behavior: 'smooth' };
    } else {
      return { top: 0, behavior: 'smooth' };
    }
  }
});

// // Add a beforeEach hook to handle redirection
// router.beforeEach((to, from, next) => {
//   // Check if the path contains "/invite/" anywhere in the URL
//   if (to.fullPath.includes('/invite/')) {
//     // Redirect to Google Play Store
//     window.location.href = 'https://dbzmg.app.link/e/yKnC9odbTMb';
//   } else {
//     next(); // Proceed if the URL does not contain "/invite/"
//   }
// });

export default router;
