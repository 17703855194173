<template>
  <div class="privacy-policy">
    <h1>Privacy Policy</h1>
    <p>Last Updated: {{ lastUpdated }}</p>

    <section v-for="(section, index) in policySections" :key="index">
      <h2>{{ section.title }}</h2>
      <div v-html="section.content"></div>
    </section>
    
    <!-- Link to Account Deletion Page -->
    <div class="deletion-link-container">
      <p>To request deletion of your account and personal data, please visit our <router-link to="/account-deletion">Account Deletion Request</router-link> page.</p>
    </div>
    
    <!-- Link to Child Safety Standards -->
    <div class="child-safety-link-container">
      <p>For information about our commitment to child safety and prevention of child sexual abuse and exploitation (CSAE), please review our <router-link to="/child-safety-standards">Child Safety Standards</router-link>.</p>
    </div>
    
    <!-- Account Deletion Request Modal -->
    <div class="modal" v-if="showDeletionModal">
      <div class="modal-content">
        <span class="close-button" @click="showDeletionModal = false">&times;</span>
        <h2>Request Account Deletion</h2>
        <p>Please provide the following information to request account deletion:</p>
        <div class="form-group">
          <label for="firstName">First Name</label>
          <input type="text" id="firstName" v-model="userData.firstName" placeholder="First Name" required>
        </div>
        <div class="form-group">
          <label for="lastName">Last Name</label>
          <input type="text" id="lastName" v-model="userData.lastName" placeholder="Last Name" required>
        </div>
        <div class="form-group">
          <label for="userEmail">Email Address</label>
          <input type="email" id="userEmail" v-model="userData.email" placeholder="Your email address" required>
        </div>
        <div class="form-actions">
          <button @click="showDeletionModal = false" class="cancel-btn">Cancel</button>
          <button @click="submitDeletionRequest" class="submit-btn" :disabled="!isValidForm">Submit Request</button>
        </div>
        <div v-if="requestStatus" :class="['status-message', requestStatus.type]">
          {{ requestStatus.message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  props: {
    showDeletionForm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      lastUpdated: new Date().toLocaleDateString(),
      showDeletionModal: false,
      userData: {
        firstName: '',
        lastName: '',
        email: ''
      },
      requestStatus: null,
      policySections: [
        {
          title: '1. Introduction',
          content: `
            <p>At Kinfolk, we understand the importance of privacy, especially when it comes to family connections and personal memories. This Privacy Policy is designed to help you understand how we collect, use, share, and protect your personal information. We are committed to safeguarding your privacy and ensuring that your personal data is handled with the utmost care and respect.</p>
          `
        },
        {
          title: '2. Information We Collect',
          content: `
            <h3>2.1 Personal Information</h3>
            <p>We collect information that you provide directly to us when you create an account, use our services, or communicate with us. This may include:</p>
            <ul>
              <li>Name, email address, and phone number</li>
              <li>Date of birth and gender</li>
              <li>Family relationships and connections</li>
              <li>Profile pictures and other media you upload</li>
              <li>Payment information (if applicable)</li>
            </ul>

            <h3>2.2 Usage Data</h3>
            <p>We automatically collect information about how you interact with our platform, including:</p>
            <ul>
              <li>Pages or features you access</li>
              <li>Time spent on the platform</li>
              <li>Actions taken within the app (e.g., creating events, uploading memories)</li>
              <li>Search queries</li>
            </ul>

            <h3>2.3 Device Information</h3>
            <p>We collect information about the devices you use to access Kinfolk, such as:</p>
            <ul>
              <li>Device type and model</li>
              <li>Operating system</li>
              <li>Browser type</li>
              <li>IP address</li>
              <li>Mobile device identifiers</li>
            </ul>

            <h3>2.4 Information from Third-Party Sources</h3>
            <p>We may receive information about you from other sources, including:</p>
            <ul>
              <li>Other Kinfolk users who invite you to join or tag you in content</li>
              <li>Public databases</li>
              <li>Social media platforms, if you choose to link your Kinfolk account with them</li>
            </ul>
          `
        },
        {
          title: '3. How We Use Your Information',
          content: `
            <p>We use the information we collect for various purposes, including:</p>
            <h3>3.1 Service Delivery</h3>
            <p>To provide and maintain the Kinfolk platform</p>
            <p>To create and manage your account</p>
            <p>To facilitate connections between family members</p>
            <p>To store and organize your family memories and events</p>

            <h3>3.2 Communication</h3>
            <p>To send you important notifications about the service</p>
            <p>To respond to your inquiries and support requests</p>
            <p>To send you marketing communications (with your consent)</p>

            <h3>3.3 Analytics and Improvement</h3>
            <p>To analyze usage patterns and trends</p>
            <p>To improve our platform and develop new features</p>
            <p>To personalize your experience on Kinfolk</p>

            <h3>3.4 Legal Compliance</h3>
            <p>To comply with applicable laws and regulations</p>
            <p>To respond to legal requests and prevent harm</p>
          `
        },
        {
          title: '4. Sharing Your Information',
          content: `
            <p>We are committed to protecting your privacy and will not sell your personal information. We may share your information in the following circumstances:</p>
            <h3>4.1 With Your Consent</h3>
            <p>We will share your information with third parties only when you have given us explicit consent to do so.</p>

            <h3>4.2 With Family Members and Connections</h3>
            <p>The nature of our service involves sharing information with family members and connections you add to your Kinfolk network. You can control the visibility of your information through our privacy settings.</p>

            <h3>4.3 Service Providers</h3>
            <p>We may share your information with third-party service providers who perform services on our behalf, such as hosting, data analysis, payment processing, and customer service. These providers are contractually obligated to protect your information and can only use it to provide services to Kinfolk.</p>

            <h3>4.4 Legal Requirements</h3>
            <p>We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).</p>

            <h3>4.5 Business Transfers</h3>
            <p>If Kinfolk is involved in a merger, acquisition, or sale of all or a portion of its assets, your information may be transferred as part of that transaction. We will notify you via email and/or a prominent notice on our website of any change in ownership or uses of your personal information.</p>
          `
        },
        {
          title: '5. Data Security',
          content: `
            <p>We take the security of your personal information seriously and employ industry-standard measures to protect your data:</p>
            <ul>
              <li>Encryption: We use SSL/TLS encryption to protect data in transit and at rest.</li>
              <li>Access Controls: We implement strict access controls and authentication measures to ensure only authorized personnel can access your data.</li>
              <li>Regular Audits: We conduct regular security audits and vulnerability assessments to identify and address potential security risks.</li>
              <li>Employee Training: Our employees undergo regular privacy and security training to ensure they understand the importance of protecting your data.</li>
            </ul>
            <p>While we strive to use commercially acceptable means to protect your personal information, no method of transmission over the Internet or method of electronic storage is 100% secure. We cannot guarantee its absolute security.</p>
          `
        },
        {
          title: '6. User Rights',
          content: `
            <p>Depending on your location, you may have certain rights regarding your personal information:</p>
            <h3>6.1 Access and Portability</h3>
            <p>You have the right to request a copy of the personal information we hold about you and to receive it in a structured, commonly used format.</p>

            <h3>6.2 Correction</h3>
            <p>You can request that we correct any inaccurate or incomplete personal information we hold about you.</p>

            <h3>6.3 Deletion</h3>
            <div class="deletion-section">
              <p class="deletion-intro">You have the right to request deletion of your account and personal information.</p>
              
              <div class="deletion-steps">
                <h4>How to Delete Your Account:</h4>
                <div class="step-container">
                  <div class="step">
                    <span class="step-number">1.</span>
                    <span class="step-text">Log into your Kinfolk app</span>
                  </div>
                  <div class="step">
                    <span class="step-number">2.</span>
                    <span class="step-text">Navigate to the "More" tab in the navigation bar</span>
                  </div>
                  <div class="step">
                    <span class="step-number">3.</span>
                    <span class="step-text">Select "Delete Account"</span>
                  </div>
                  <div class="step">
                    <span class="step-number">4.</span>
                    <span class="step-text">Confirm your decision to delete the account</span>
                  </div>
                </div>
                
                <div class="web-deletion">
                  <p>Alternatively, you can <a href="#" class="deletion-link" onclick="event.preventDefault();">request account deletion here</a>.</p>
                </div>
              </div>

              <div class="data-policy">
                <h4>What Happens to Your Data:</h4>
                <p>Upon account deletion, we permanently remove:</p>
                <div class="list-container">
                  <ul class="data-list">
                    <li>Profile information</li>
                    <li>Uploaded media</li>
                    <li>Family connections</li>
                    <li>Account settings</li>
                    <li>All other associated data</li>
                  </ul>
                </div>
                <p class="policy-note"><strong>Note:</strong> We do not retain any user data after account deletion.</p>
              </div>
            </div>
          `
        },
        {
          title: '7. Cookies and Tracking Technologies',
          content: `
            <p>Kinfolk uses cookies and similar tracking technologies to enhance your experience on our platform:</p>
            <h3>7.1 Essential Cookies</h3>
            <p>These cookies are necessary for the website to function and cannot be switched off. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in, or filling in forms.</p>

            <h3>7.2 Analytics Cookies</h3>
            <p>These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site.</p>

            <h3>7.3 Functionality Cookies</h3>
            <p>These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third-party providers whose services we have added to our pages.</p>

            <h3>7.4 Advertising Cookies</h3>
            <p>These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites.</p>
            <p>You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. However, if you disable or refuse cookies, please note that some parts of Kinfolk may become inaccessible or not function properly.</p>
          `
        },
        {
          title: '8. Childrens Privacy',
          content: `
            <p>Kinfolk is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us. If we become aware that we have collected personal information from a child under 13 without verification of parental consent, we take steps to remove that information from our servers.</p>
          `
        },
        {
          title: '9. International Data Transfers',
          content: `
            <p>Your information, including personal data, may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those in your jurisdiction.</p>
            <p>If you are located outside the United States and choose to provide information to us, please note that we transfer the data, including personal data, to the United States and process it there. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
            <p>Kinfolk will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your personal data will take place to an organization or a country unless there are adequate controls in place, including the security of your data and other personal information.</p>
          `
        },
        {
          title: '10. Changes to This Policy',
          content: `
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date at the top of this Privacy Policy.</p>
            <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
          `
        }
      ]
    }
  },
  computed: {
    isValidEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(this.userData.email);
    },
    isValidForm() {
      return this.isValidEmail && 
             this.userData.firstName.trim() !== '' && 
             this.userData.lastName.trim() !== '';
    }
  },
  mounted() {
    // Add the deletion request link to the last section
    this.policySections.forEach(section => {
      if (section.title === '8. Your Rights and Choices') {
        section.content += `
          <p>You have the right to request deletion of your personal information. To make such a request, please visit our <a href="/account-deletion">Account Deletion Request</a> page.</p>
        `;
      }
    });
    
    // Show deletion modal if accessed via the dedicated URL
    if (this.showDeletionForm) {
      this.showDeletionModal = true;
    }
    
    // Add event listener for deletion link
    this.$nextTick(() => {
      const deletionLinks = document.querySelectorAll('.deletion-link');
      deletionLinks.forEach(link => {
        link.addEventListener('click', (e) => {
          e.preventDefault();
          this.showDeletionModal = true;
        });
      });
    });
  },
  methods: {
    submitDeletionRequest() {
      if (!this.isValidForm) return;
      
      // Set status to loading
      this.requestStatus = {
        type: 'info',
        message: 'Processing your request...'
      };
      
      // Prepare the data for the API call
      const requestData = {
        email: this.userData.email,
        first_name: this.userData.firstName,
        last_name: this.userData.lastName,
        medium: 5 // Using 5 to indicate a deletion request
      };
      
      // Make the API call to the subscription endpoint
      fetch('https://api.kinfolk.world/subscription/add-new', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData)
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('Deletion request submitted:', data);
        
        // Set success status
        this.requestStatus = {
          type: 'success',
          message: 'Your deletion request has been submitted. We will process your request and contact you shortly.'
        };
        
        // Clear the form after 3 seconds and close modal
        setTimeout(() => {
          this.userData = {
            firstName: '',
            lastName: '',
            email: ''
          };
          this.showDeletionModal = false;
          this.requestStatus = null;
        }, 3000);
      })
      .catch(error => {
        console.error('Error submitting deletion request:', error);
        
        // Set error status
        this.requestStatus = {
          type: 'error',
          message: 'There was an error processing your request. Please try again later.'
        };
      });
    }
  }
}
</script>

<style scoped>
.privacy-policy {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  position: relative; /* For modal positioning */
}

h1 {
  color: #1F93E7;
  border-bottom: 2px solid #1F93E7;
  padding-bottom: 10px;
}

h2 {
  color: #1665C4;
  margin-top: 30px;
}

h3 {
  color: #333;
  margin-top: 20px;
}

ul {
  padding-left: 20px;  /* Increased left padding */
}

p {
  margin-bottom: 15px;
}

.deletion-section {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  margin: 15px 0;
}

.deletion-intro {
  font-size: 1.1em;
  color: #2c3e50;
  margin-bottom: 20px;
}

.deletion-steps h4, .data-policy h4 {
  color: #1F93E7;
  margin-bottom: 15px;
  font-size: 1.1em;
}

.step-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 25px;
}

.step {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-left: 25px;
}

.step-number {
  color: #1F93E7;
  font-weight: bold;
  min-width: 25px;
}

.step-text {
  color: #2c3e50;
}

.web-deletion {
  margin-top: 15px;
  padding: 10px;
  background-color: #e9f5ff;
  border-radius: 6px;
  text-align: center;
}

.deletion-link {
  color: #1F93E7;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.deletion-link:hover {
  color: #1665C4;
}

.data-policy {
  background: white;
  padding: 15px;
  border-radius: 6px;
  border-left: 4px solid #1F93E7;
}

.list-container {
  padding-left: 25px;
}

.data-list {
  list-style-type: none;
  margin: 15px 0;
  padding-left: 0;
}

.data-list li {
  padding: 8px 0;
  padding-left: 25px;
  position: relative;
}

.data-list li:before {
  content: "•";
  color: #1F93E7;
  font-weight: bold;
  position: absolute;
  left: 0;
}

.policy-note {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #e9ecef;
  font-size: 0.95em;
}

/* Modal Styles */
.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  position: relative;
  box-shadow: 0 4px 20px rgba(0,0,0,0.15);
}

.close-button {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
  color: #aaa;
}

.close-button:hover {
  color: #333;
}

.form-group {
  margin: 20px 0;
}

.form-group input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 20px;
}

.cancel-btn, .submit-btn {
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  border: none;
}

.cancel-btn {
  background-color: #f1f1f1;
  color: #333;
}

.submit-btn {
  background-color: #1F93E7;
  color: white;
}

.submit-btn:disabled {
  background-color: #a0cff0;
  cursor: not-allowed;
}

.status-message {
  margin-top: 20px;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
}

.status-message.success {
  background-color: #d4edda;
  color: #155724;
}

.status-message.error {
  background-color: #f8d7da;
  color: #721c24;
}

.status-message.info {
  background-color: #e9f5ff;
  color: #1665C4;
}

.deletion-link-container {
  margin-top: 20px;
  padding: 10px;
  background-color: #e9f5ff;
  border-radius: 6px;
  text-align: center;
}

.child-safety-link-container {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f0f4ff;
  border-radius: 6px;
  text-align: center;
  border: 1px solid rgba(79, 70, 229, 0.2);
}
</style>